import activity from './m-workshop.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Workshop',
  icon: 'mdi-sprout',
  description: 'Do it yourself with this video guide',
  status: true,
  styling: {
    borderColor: '#6eba80'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-workshop',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        videoaskLink: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
